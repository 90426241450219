import { NavLink } from "@remix-run/react";
import useLinkHref from "app/utils/href";
import classNames from "classnames";
import PropTypes from "prop-types";

const SubLink = ({ hash, sublink, NavLinkProps }) => {
  return (
    <div className="text-sm font-medium text-white bg-gradient-to-b from-yellowgreen to-cyan-dark xl:text-xs xl:font-semibold">
      <NavLink
        {...NavLinkProps}
        to={useLinkHref(`${sublink.url}${hash ?? ""}`)}
        id={sublink.id}
        className={({ isActive }) =>
          classNames(
            "flex items-center gap-x-2 py-3.5 hover:w-[99%] hover:bg-secondary-dark ltr:pl-12 rtl:pr-12 xl:py-3 hover:xl:w-auto hover:xl:bg-primary-light ltr:xl:pl-3 rtl:xl:pr-3",
            isActive
              ? "w-[99%] bg-secondary-dark xl:w-auto xl:border-x-green xl:bg-primary-light xl:font-bold ltr:xl:border-r-[4px] rtl:xl:border-l-[4px]"
              : "bg-primary-pale xl:border-x-primary-pale"
          )
        }
        reloadDocument={sublink.reloadDocument}
      >
        {sublink.label}
      </NavLink>
    </div>
  );
};

const SubLinks = ({
  headerIsOnTop,
  isDropdownOpen,
  sublinks,
  hash,
  NavLinkProps,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col transition-all duration-100 ease-in-out xl:visible xl:absolute xl:top-[38px] xl:block xl:h-auto xl:w-44 xl:rounded-b-md xl:border-2 xl:border-primary-light xl:bg-primary-semilight xl:opacity-100 xl:shadow-md xl:transition-none",
        isDropdownOpen
          ? `h-[${sublinks.length * 48}px] opacity-100`
          : "invisible h-0 opacity-0",
        headerIsOnTop ? "top-8 rounded-t-md" : "rounded-tr-md"
      )}
    >
      {sublinks.map((sublink, index) => (
        <SubLink
          key={index}
          sublink={sublink}
          NavLinkProps={NavLinkProps}
          hash={hash}
        />
      ))}
    </div>
  );
};

export default SubLinks;

SubLinks.propTypes = {
  headerIsOnTop: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  sublinks: PropTypes.array,
  hash: PropTypes.string,
};
