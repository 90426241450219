import DashboardLoginButton from "app/components/buttons/dashboard-login";
import Sprite from "app/components/sprite";
import {
  ZBNI_CSHOP_MARKET_URL,
  ZBNI_CUSTOMER_SUPPORT,
} from "app/config/constants";
import { useI18nLanguage } from "app/hooks/i18n";
import externalLink from "app/images/external-link.png";
import egyptFlag from "app/images/flags/egypt.png";
import jordanFlag from "app/images/flags/jordan.png";
import saudiFlag from "app/images/flags/saudi-arabia.png";
import uaeFlag from "app/images/flags/uae.png";
import secureTrust from "app/images/logos/secure-trust.png";
import whatsapp from "app/images/logos/whatsapp.png";
import Link from "app/components/i18n/Link";
import { Trans } from "react-i18next";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const YTLink = "https://www.youtube.com/channel/UCslWxX_PWfH3cioB_CED1Gg";
  const { locale: language } = useI18nLanguage();

  return (
    <footer
      className="relative z-10 overflow-hidden bg-primary-semilight text-base
      text-white"
      lang={language}
    >
      <Sprite
        name="ellipse"
        fill="#101820"
        className="absolute right-[-600px] top-[350px] z-[-10]
        h-[1500px] w-[300%] lg:right-[-300px] lg:top-[-100px]
        lg:w-[100%] xl:right-[-400px] xl:top-[50px]"
      />
      <div className="zbooni-layout">
        <div className="py-8">
          <Link to="/" id="cta_footer_zbooniLogo_in">
            <Sprite
              name={`zbooni-${language}`}
              className="h-10 w-[135px] xl:w-[154px]"
            />
          </Link>
        </div>
        <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-12">
          <div className="col-span-8 text-center">
            <div
              className="grid grid-flow-row grid-cols-1
              text-left rtl:text-right lg:grid-cols-3"
            >
              <div className="mb-8">
                <p
                  className="mb-1 font-semibold uppercase
                  text-secondary ltr:text-[10px] rtl:text-base"
                >
                  <Trans>Our Locations</Trans>
                </p>
                <div
                  className="grid grid-flow-row
                  grid-cols-2 text-sm lg:grid-cols-1"
                >
                  <div className="py-1">
                    <img
                      className="inline ltr:mr-2 rtl:ml-2"
                      src={uaeFlag}
                      alt="uae"
                    />
                    <span>
                      <Trans>UAE</Trans>
                    </span>
                  </div>
                  <div className="py-1">
                    <img
                      className="inline ltr:mr-2 rtl:ml-2"
                      src={saudiFlag}
                      alt="ksa"
                    />
                    <span>
                      <Trans>KSA</Trans>
                    </span>
                  </div>
                  <div className="py-1">
                    <img
                      className="inline ltr:mr-2 rtl:ml-2"
                      src={jordanFlag}
                      alt="jordan"
                    />
                    <span>
                      <Trans>Jordan</Trans>
                    </span>
                  </div>
                  <div className="py-1">
                    <img
                      className="inline ltr:mr-2 rtl:ml-2"
                      src={egyptFlag}
                      alt="egypt"
                    />
                    <span>
                      <Trans>Egypt</Trans>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-12 lg:mb-8">
                <p
                  className="mb-1 font-semibold uppercase
                  text-secondary ltr:text-[10px] rtl:text-base"
                >
                  <Trans>Company</Trans>
                </p>
                <div className="text-sm font-semibold">
                  <Link
                    to="/features/capture-orders/"
                    className="block py-1"
                    id="cta_footer_features_in"
                  >
                    <Trans>Features</Trans>
                  </Link>
                  <Link
                    to="/who-we-serve/"
                    className="block py-1"
                    id="cta_footer_whoWeServe_in"
                  >
                    <Trans>Who We Serve</Trans>
                  </Link>
                  <Link
                    to="/about-us/"
                    className="block py-1"
                    id="cta_footer_aboutUs_in"
                  >
                    <Trans>About us</Trans>
                  </Link>
                  <a
                    href={`${ZBNI_CSHOP_MARKET_URL}?utm_source=website&utm_medium=zbooni_web_footer_nav&utm_campaign=zbooni_cshop&utm_content=nav_zbooni_website`}
                    target="_blank"
                    rel="noreferrer"
                    className="block py-1"
                    id="cta_footer_marketplace_in"
                  >
                    <Trans>cShop Market</Trans>
                    <img
                      alt=""
                      src={externalLink}
                      className="inline w-3.5 brightness-0 invert ltr:ml-2.5 rtl:mr-2.5"
                    />
                  </a>
                  <Link
                    to="/blog/"
                    className="block py-1"
                    id="cta_footer_blog_in"
                  >
                    <Trans>Blogs</Trans>
                  </Link>
                </div>
              </div>
              <div>
                <div className="mb-8 font-semibold">
                  <p
                    className="mb-1 uppercase
                    text-secondary ltr:text-[10px] rtl:text-base"
                  >
                    <Trans>Email us</Trans>
                  </p>
                  <a
                    className="ltr:text-md bg-gradient-to-r
                    from-cyan-dark to-yellowgreen bg-clip-text
                    font-bold text-transparent rtl:text-base"
                    href="mailto:hello@zbooni.com"
                    lang="en"
                  >
                    hello@zbooni.com
                  </a>
                </div>
                <div className="mb-8">
                  <p
                    className="mb-1 font-semibold uppercase
                    text-secondary ltr:text-[10px] rtl:text-base"
                  >
                    <Trans>Find us on</Trans>
                  </p>
                  <div className="flex gap-x-5 lg:gap-x-2.5 xl:gap-x-4">
                    <div>
                      <a
                        href="https://www.facebook.com/ZbooniApp/"
                        target="_blank"
                        rel="noreferrer"
                        id="cta_footer_facebook"
                      >
                        <Sprite
                          name="facebook"
                          fill="#FFFFFF"
                          className="h-10 w-[13px] lg:h-6 xl:h-10"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://twitter.com/zbooni_app"
                        target="_blank"
                        rel="noreferrer"
                        id="cta_footer_twitter"
                      >
                        <Sprite
                          name="twitter"
                          fill="#FFFFFF"
                          className="h-10 w-6 lg:h-6 xl:h-10"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.instagram.com/zbooni_app/"
                        target="_blank"
                        rel="noreferrer"
                        id="cta_footer_instagram"
                      >
                        <Sprite
                          name="instagram"
                          fill="#FFFFFF"
                          className="h-10 w-[34px] lg:h-6 xl:h-10"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.linkedin.com/company/zbooni/"
                        target="_blank"
                        rel="noreferrer"
                        id="cta_footer_linkedin"
                      >
                        <Sprite
                          name="linkedin"
                          fill="#FFFFFF"
                          className="h-10 w-[30px] lg:h-6 xl:h-10"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href={YTLink}
                        target="_blank"
                        rel="noreferrer"
                        id="cta_footer_youtube"
                      >
                        <Sprite
                          name="youtube"
                          fill="#FFFFFF"
                          className="h-10 w-[32px] lg:h-6 xl:h-10"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mt-10 hidden grid-flow-row
              grid-cols-12 lg:mt-0 lg:grid"
            >
              <div className="flex items-center ltr:mr-3 rtl:ml-3">
                <img className="inline" src={secureTrust} alt="secure-trust" />
              </div>
              <div
                className="col-span-11 flex w-[95%]
                items-center ltr:text-left rtl:text-right"
              >
                <p
                  className="m-0 inline-block w-[95%]
                  text-[10px] leading-4 text-secondary"
                >
                  <Trans>
                    Based upon information provided by zbooni regarding its
                    policies, procedures, and technical systems that store,
                    process and/or transmit cardholder data, zbooni has
                    performed the required procedures to validate compliance
                    with the PCI DSS.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-span-4 mb-8 border-primary-light ltr:text-left
            rtl:text-right ltr:lg:border-l ltr:lg:pl-10 ltr:lg:pr-0
            rtl:lg:border-r rtl:lg:pl-0 rtl:lg:pr-10 xl:mb-0 xl:px-10"
          >
            <div>
              <p
                className=" mb-1 font-semibold uppercase
                text-secondary ltr:text-[10px] rtl:text-base"
              >
                <Trans>Business Dashboard</Trans>
              </p>
              <p
                className="mb-1 font-normal text-secondary
                ltr:text-xs rtl:text-sm"
              >
                <Trans>Are you already on Zbooni?</Trans>
              </p>
              <DashboardLoginButton
                id="cta_footer_dashboardLogin"
                additionalClassNames="mt-5 w-[170px] rounded-full bg-white p-2 text-xs font-black text-primary"
                iconColor="#282F3A"
                useShortDisplay={true}
              />
            </div>
            <hr className="my-8 w-full border-t border-primary-light" />
            <div>
              <p
                className=" mb-1 font-semibold uppercase
                text-secondary ltr:text-[10px] rtl:text-base"
              >
                <Trans>Contact us</Trans>
              </p>
              <p
                className="mb-1 font-normal text-secondary
                ltr:text-xs rtl:text-sm"
              >
                <Trans>
                  We are always happy to answer any queries you have.
                </Trans>
              </p>
              <a
                href={`${ZBNI_CUSTOMER_SUPPORT}&text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.`}
                rel="noreferrer"
                className="mt-5 flex w-[170px] items-center
                justify-center rounded-full bg-green-darkest
                p-2 text-xs font-black text-primary"
              >
                <img src={whatsapp} alt="whatsapp" />
                <span className="font-extrabold text-white ltr:ml-1 rtl:mr-1">
                  <Trans>Let&apos;s chat</Trans>
                </span>
              </a>
            </div>
            <div
              className="mt-10 grid grid-flow-row grid-cols-4
              gap-4 border-b border-primary-light pb-8
              md:grid-cols-8 md:gap-8 lg:hidden"
            >
              <div className="items-center">
                <img
                  className="inline w-[58px]"
                  src={secureTrust}
                  alt="secure-trust"
                />
              </div>
              <div
                className="col-span-3 flex w-[95%] items-center
                ltr:text-left rtl:text-right md:col-span-7"
              >
                <p
                  className="m-0 inline-block w-[95%]
                  text-[10px] leading-4 text-secondary"
                >
                  <Trans>
                    Based upon information provided by zbooni regarding its
                    policies, procedures, and technical systems that store,
                    process and/or transmit cardholder data, zbooni has
                    performed the required procedures to validate compliance
                    with the PCI DSS.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 space-y-3 pb-10 text-xs font-semibold lg:pb-0">
          <div className="text-secondary ltr:mr-3 rtl:ml-3 lg:inline">
            © {year}, <Trans>Zbooni</Trans>.&nbsp;
            <Trans>All rights reserved.</Trans>
          </div>
          <div className="lg:inline">
            <Link
              to="/terms/"
              className="inline-block hover:text-secondary"
              id="cta_footer_termsAndCo<Lnditions_in"
            >
              <Trans>Terms and Conditions</Trans>
            </Link>
            <span className="mx-2">|</span>
            <Link
              to="/data-privacy-policy/"
              className="hover:text-secondary"
              id="cta_footer_privacyPolicy_in"
            >
              <Trans>Privacy Policy</Trans>
            </Link>
            <span className="mx-2">|</span>
            <Link
              to="/payment-policy/"
              className="hover:text-secondary"
              id="cta_footer_paymentPolicy_in"
            >
              <Trans>Payment Policy</Trans>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
